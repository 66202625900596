<template>
    <div class="pageContainer weijiPage flexCloumn">
        <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1">
                <tchtree v-model="searchForm.class_ids"></tchtree>
                <el-input placeholder="请输入关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
                    style="width:300px" clearable>
                    <el-select v-model="searchForm.ktype" slot="prepend" placeholder="请选择" style="width:120px">
                        <el-option label="学生姓名" value="stu_name"></el-option>

                    </el-select>
                </el-input>


                <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary" @click="() => { page.current_page = 1; getList()}">
                    搜索
                </el-button>

                <el-button icon="el-icon-plus" style="margin-left:10px" size="small" type="primary" @click="add"
                    plain>添加</el-button>
            </div>


        </el-row>
        <div class="dividerBar"></div>

        <el-table :data="DataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
            <el-table-column type="index" width="65" label="序号" align="center" />
            <el-table-column prop="stu_code" label="学号" width="135" align="center" />
            <el-table-column prop="stu_name" label="学生姓名" width="135" align="center" />
            <el-table-column prop="instructor" label="就业老师" width="135" align="center" />
            <el-table-column prop="enter_name" label="实习单位" show-overflow-tooltip width="160" align="center" />
            <el-table-column prop="weiji_time" label="突发日期" width="135" align="center" />


            <el-table-column prop="reason" label="情况说明" show-overflow-tooltip width="160" align="center"></el-table-column>

            <el-table-column prop="create_name" label="创建人" width="90" align="center" />

            <el-table-column prop="create_date" label="创建时间" show-overflow-tooltip width="200" align="center" />


            <el-table-column fixed="right" label="操作" width="150" align="center">
                <template slot-scope="scope">
                    <el-tag class="pointer" type="primary" size="mini" @click="viewInfo(scope.row)">详情</el-tag>
                    <template v-if="!$store.getters.isGradeLeader(scope.row.class_id)">
                      <el-tag class="pointer ml10" type="success" size="mini" @click="modify(scope.row)">修改</el-tag>
                      <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
                                     iconColor="red" @confirm="del(scope.row)" placement="left" title="是否确定删除？">
                        <el-tag class="pointer ml10" slot="reference" size="mini" type="danger">删除</el-tag>

                      </el-popconfirm>
                    </template>

                </template>
            </el-table-column>
        </el-table>

        <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
            :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
            :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
        </el-pagination>


        <el-dialog :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="true"
            width="800px" custom-class="cus_dialog">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;">{{ EditItem.id ? EditItem.stu_name + ' 突发情况处理详情' : '新增突发情况处理' }} </span>
            </div>

            <div>
                <el-form :model="EditItem" ref="EditItem" label-width="120px" v-if="EditItem">
                    <el-row style="padding-top: 10px;">
                        <el-col :span="12">
                            <el-form-item label="学号">
                                <el-select v-model="EditItem.stu_num" :disabled="dialogxx" filterable remote reserve-keyword
                                    placeholder="请输入学号" :remote-method="remoteMethod" :loading="loading" style="width:100%;"
                                    @change="checkedxz" @focus="focusxz">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="学生姓名">
                                <el-input v-model="EditItem.stu_name" disabled style="width:100%;"
                                    placeholder="请先输入学号"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row style="padding-top: 10px;">
                        <el-col :span="12">
                            <el-form-item label="班主任">
                                <el-select v-model="EditItem.head_teacher" filterable placeholder="请选择班主任"
                                    style="width:100%;" :disabled="dialogxx">
                                    <el-option v-for="(item, idx) in bzrOptions" :key="idx" :label="item.username"
                                        :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="班级名称">
                                <el-input v-model="EditItem.class_name" disabled style="width:100%;"
                                    placeholder="请先输入学号"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row style="padding-top: 10px;">
                        <el-col :span="12">
                            <el-form-item label="指导老师">
                                <el-select v-model="EditItem.instructor" filterable placeholder="请选择指导老师"
                                    style="width:100%;" :disabled="dialogxx">
                                    <el-option v-for="(item, idx) in zdlsOptions" :key="idx" :label="item.username"
                                        :value="item.id" filterable:true></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="实习单位">
                                <el-select :disabled="!isModify" v-model="EditItem.enter_name" filterable
                                    placeholder="请选择实习单位" style="width:100%;">
                                    <el-option v-for="(item, idx) in enterList" :key="idx" :label="item.firm_name"
                                        :value="item.firm_name"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="突发日期">
                                <el-date-picker v-model="EditItem.weiji_time" :disabled="disabled" style="width:100%;"
                                    align="right" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions" @change="() => { $forceUpdate() }"></el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="情况说明">
                        <el-input :disabled="!isModify" type="textarea" v-model="EditItem.reason" placeholder="情况说明">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="附件">
                        <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
                            v-for="(img, idx) in EditItem.annex" :key="idx">
                            <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                                :preview-src-list="[img.fileUrl || img.src]" fit="cover"></el-image>

                            <div class="imgremove" v-if="isModify" @click="removeImg(idx)">
                                <i class="el-icon-remove"></i>
                            </div>
                        </div>

                        <div v-if="isModify" style="display:inline-block;vertical-align: top;">
                            <el-upload style="display: inline-block; margin-right: 20px" action :http-request="uploadOss"
                                :on-success="uploadFiles" :show-file-list="false" name="image">
                                <div class="imgaddbtn">＋</div>
                            </el-upload>
                        </div>
                    </el-form-item>
                </el-form>


            </div>
            <el-row slot="footer" v-if="isModify && $store.state.teacher.id == EditItem.create_id || EditItem.id == 0">
                <el-button size="small" icon="el-icon-edit" type="primary" @click="submitForm()">提交</el-button>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
import util from "../../utils/util.js";
export default {
    components: {
        tchtree
    },
    data() {
        return {
            temphead: "//school.mmteck.cn/static/temp.png",

            searchForm: {
                class_ids: "",
                sp_status: "",
                ktype: "stu_name",
                keyword: "",
                month: ""

            },
            EditItem: {
                id: 0,
                group_name: "",
                needpic: "",
                outside: "",
                latesign: "",
                annex: [],
            },
            DataList: [],

            page: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },
            thisTeacherid: 0,
            dialogshow: false,
            dialogxx: true,
            disabled: true,
            editor: null,
            optionsRow: {
                stu_code: "",
                stu_id: "",
                stu_name: "",
                class_name: ""
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },

            enterList: [],
            options: [],
            bzrOptionsls: [],
            bzrOptions: [],
            zdlsOptionsls: [],
            zdlsOptions: [],
            list: [],
            loading: false,
            states: [],
            isModify: false,

        };
    },
    mounted() {
        let year = new Date().getFullYear()
        let month = new Date().getMonth() + 1
        this.searchForm.month = year + '-' + month
        this.getList()
    },
    methods: {
        getList() {
            this.$http.post("/api/stu_weiji_list", {
                page: this.page.current_page,
                pagesize: this.page.per_page,
                ktype: this.searchForm.ktype,
                keyword: this.searchForm.keyword,
                class_ids: this.searchForm.class_ids
            }).then(res => {
                this.DataList = res.data.data
                this.page = res.data.page
            })
        },


        viewInfo(row) {
            row.stu_num = row.stu_code
            this.EditItem = row
            if (this.EditItem.annex && this.isJSON(this.EditItem.annex)) {
                this.EditItem.annex = JSON.parse(this.EditItem.annex);
            }
            this.isModify = false
            this.dialogshow = true;
        },
        add() {

            this.EditItem.id = 0;
            this.dialogshow = true;
            this.enter_list();
            this.disabled = false;
            this.dialogxx = false;
            this.isModify = true

        },
        modify(row) {
            row.stu_num = row.stu_code
            this.EditItem = row
            this.isModify = true
            this.enter_list();
            this.disabled = true;
            this.dialogxx = true;
            if (this.EditItem.annex && this.isJSON(this.EditItem.annex)) {
                this.EditItem.annex = JSON.parse(this.EditItem.annex);
            }
            this.options = [{ id: this.EditItem.stu_id, label: this.EditItem.stu_code }];
            this.zdlsOptions = [
                { id: this.EditItem.instructor, username: this.EditItem.instructor_name }
            ];
            this.bzrOptions = [
                { id: this.EditItem.head_teacher, username: this.EditItem.head_teacher_name }
            ];
            if (this.$store.state.teacher.id == this.EditItem.create_id) {
                this.disabled = false;
            } else {
                //修改不是自己的
            }
            this.dialogshow = true;


        },
        del(row) {
            this.$http.post("/api/stu_weiji_delete", {
                id: row.id
            }).then(res => {
                this.$message.success('删除成功')
                this.getList();
            })
        },
        remoteMethod(query) {
            if (query !== "") {
                this.loading = true;
                this.$http
                    .post("/api/students_class_info", { stu_code: query })
                    .then(res => {
                        this.loading = false;
                        if (res.data) {
                            this.options = [
                                {
                                    value: res.data.id,
                                    label: res.data.stu_name + "-" + res.data.stu_code
                                }
                            ];
                            this.bzrOptionsls = res.data.bzr_ist;
                            this.zdlsOptionsls = res.data.zdls_list;
                            this.optionsRow.stu_code = res.data.stu_code;
                            this.optionsRow.stu_id = res.data.id;
                            this.optionsRow.stu_name = res.data.stu_name;
                            this.optionsRow.class_name = res.data.class_name;
                        }
                    });
            } else {
                this.options = [];
            }
        },
        checkedxz() {
            this.dialogxx = false;
            this.bzrOptions = this.bzrOptionsls;
            this.zdlsOptions = this.zdlsOptionsls;

            this.EditItem.head_teacher = this.bzrOptions[0].id;
            if (this.zdlsOptions.length > 0) {
                this.EditItem.instructor = this.zdlsOptions[0].id;
            }

            this.EditItem.stu_code = this.optionsRow.stu_code;
            this.EditItem.stu_id = this.optionsRow.stu_id;
            this.EditItem.stu_name = this.optionsRow.stu_name;
            this.EditItem.class_name = this.optionsRow.class_name;
        },
        focusxz() {
            this.options = [];
            this.bzrOptionsls = [];
            this.zdlsOptions = [];
            this.optionsRow.instructor = "";
            this.optionsRow.head_teacher = "";
            this.optionsRow.stu_code = "";
            this.optionsRow.stu_id = "";
            this.optionsRow.stu_name = "";
            this.optionsRow.class_name = "";
        },
        uploadFiles(e) {
            this.EditItem.annex.push(e);
        },
        removeImg(idx) {
            this.EditItem.annex.splice(idx, 1);
        },
        enter_list() {
            this.$http.post("/api/enterprise_list_all").then(res => {
                this.enterList = res.data;
            });
        },
        submitForm(st) {
            let EditItem = { ...this.EditItem };
            EditItem.annex = JSON.stringify(EditItem.annex);
            let data = JSON.parse(JSON.stringify(EditItem));

            if (!data.stu_id) {
                this.$message.error("请输入学号，选择学生~");
                return false;
            }
            if (!data.head_teacher) {
                this.$message.error("请选择班主任~");
                return false;
            }
            if (!data.enter_name.trim()) {
                this.$message.error("请选择实习单位~");
                return false;
            }
            if (!data.weiji_time.trim()) {
                this.$message.error("请选择违纪日期~");
                return false;
            }
            if (!data.reason.trim()) {
                this.$message.error("请填写情况说明~");
                return false;
            }

            this.$http.post("/api/stu_weiji_edit", data).then(res => {
                this.$message({
                    type: "success",
                    message: "提交成功"
                });

                this.getList();
                this.dialogshow = false;
            });
        },

        handlePageChange(page) {
            this.page.current_page = page
            this.getList()
        },
        handleSizeChange(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getList()
        },
    },
}
</script>

<style>
.weijiPage .el-input.is-disabled .el-input__inner {
    color: #606266;
}

.weijiPage .el-textarea__inner {
    color: #606266 !important;
}
</style>
<style scoped>
.imgaddbtn {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dotted #ccc;
    font-size: 30px;
    cursor: pointer;
}

.imgremove {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;

    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
    color: #ccc;
}

.imgremove:hover {
    color: orange;
}

.spuser {
    display: inline-block;
    border: 1px solid rgb(255, 148, 62);


    padding: 2px;
    margin-right: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    padding: 4px 10px;
}

.spuser img {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
}
</style>
